* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

@font-face {
	font-family: 'Open Sans R';
	src: url('assets/fonts/OpenSans-Regular.ttf');
}

@font-face {
	font-family: 'Open Sans SB';
	src: url('assets/fonts/OpenSans-SemiBold.ttf');
}

@font-face {
	font-family: 'Open Sans B';
	src: url('assets/fonts/OpenSans-Bold.ttf');
}

@font-face {
	font-family: 'Moderat R';
	src: url('assets/fonts/Moderat-Regular.otf');
}

@font-face {
	font-family: 'Moderat M';
	src: url('assets/fonts/Moderat-Medium.otf');
}

@font-face {
	font-family: 'Moderat B';
	src: url('assets/fonts/Moderat-Bold.otf');
}

@font-face {
	font-family: 'Moderat EB';
	src: url('assets/fonts/Moderat-Extended-Bold.otf');
}

html,
body {
	font-family: 'Open Sans R';
	overflow-x: hidden;
}

a {
	text-decoration: none;
}

a:focus {
	outline: none;
}

button {
	outline: none;
	border: none;
}

.container-1280 {
	max-width: 1280px;
	margin: auto;
}

@media screen and (max-width: 1420px) {
	.container-1280 {
		width: 90%;
		margin: auto;
	}
}

.p-relative {
	position: relative;
}


/* MENÚ MOBILE */

.dp-none {
	display: none;
}

.header-mobile-img {
	width: 80px;
	position: absolute;
	top: 20px;
	left: 10%;
	z-index: 1;
}

.menu-toggle {
	width: 30px;
	display: none;
}

.menu-toggle span {
	display: block;
	width: 100%;
	height: 2px;
	background-color: white;
	margin-bottom: 6px;
	transform-origin: 0px 100%;
	transition: all 400ms;
}

.menu-toggle span:last-of-type {
	margin-bottom: 0;
}

.activeline2 {
	opacity: 0;
}

.logo-header-mobile {
	width: 150px;
	margin-bottom: 30px;
}

.logo-menu-mobile {
	display: none;
}

.menu-mobile {
	display: none;
}

.offcanvas-header .btn-close {
	background: url('assets/images/iconos/bx-x.svg') center/1.5em auto no-repeat;
	opacity: 1;
}

.offcanvas-header .btn-close {
	margin-top: 20px;
	margin-right: 20px;
	margin-bottom: calc(var(--bs-offcanvas-padding-y) * -.5);
}

.menu-mobile-container {
	display: flex;
	position: fixed;
	align-items: center;
	padding: 15px 10%;
	justify-content: space-between;
	width: 100%;
	z-index: 99;
}

.btn.menu-mobile-btn:hover,
.btn.menu-mobile-btn:focus {
	border: none;
}

.menu-mobile ul {
	padding: 0;
}

.menu-mobile ul li {
	list-style: none;
	margin-bottom: 20px;
}

.menu-mobile ul li a {
	text-decoration: none;
	color: #103D22;
}

.dropdown-mobile {
	padding-left: 20px;
	background-image: url('assets/images/iconos/nav-arrow-mobile.svg');
	background-repeat: no-repeat;
	background-position: 8px 12px;
	font-size: 15px;
	padding-top: 0.5em;
}

.dropdown-mobile ul li {
	text-decoration: none;
	color: #103D22;
	margin-bottom: 1px;
}

@media screen and (max-width: 992px) {
	.logo-menu-mobile {
		display: block;
		width: 140px;
	}
}

@media screen and (max-width: 360px) {
	.menu-mobile-btn {
		left: 75%;
	}
}

.rrss-header-mobile-container {
	margin-top: 30px;
}

.rrss-header-mobile-container p {
	color: #103D22;
	font-size: 14px;
}

.rrss-header-mobile {
	display: flex;
	column-gap: 10px;
}

.rrss-header-mobile img {
	height: 32px;
}

@media screen and (max-width:420px) {
	.menu-toggle {
		left: 85%;
	}

	.menu-mobile.active {
		max-width: 90vw;
	}
}

@media screen and (max-width: 992px) {
	.menu-toggle {
		display: block;
	}

	.header-mobile-img {
		display: block;
	}

	.menu-mobile {
		display: block;
		padding: 0 25px;
		padding-left: 40px;
	}
}

.offcanvas-header {
	justify-content: flex-end;
}

.owl-carousel .owl-dots {
	margin: auto;
	text-align: center;
}

.owl-carousel .owl-dots button {
	border: 1px solid white;
	width: 18px;
	height: 18px;
	border-radius: 50%;
	margin: 0 10px;
}

.owl-carousel .owl-dots button.owl-dot.active {
	background-color: white;
}


/* MENÚ DESKTOP */

header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 99;
	padding: 15px 0;
}

.logo-header-desktop {
	height: 20px;
}

.header-container {
	margin: auto;
	justify-content: space-between;
	display: flex;
	align-items: center;
	position: relative;
}

.header-links-container ul {
	padding: 0;
	margin: 0;
}

.header-links-container ul li {
	list-style: none;
	display: inline;
	padding: 0 20px;
	font-size: 14px;
}

.header-links-container ul li a {
	color: white;
}

.header-links-container ul li a:hover,
.header-links-container ul li a:active,
.header-links-container ul li a:focus {
	color: #47B83A;
	font-family: 'Open Sans B';
}

.header-links-container ul li a.active {
	color: #47B83A;
	font-family: 'Open Sans B';
}

.header-links-container .active {
	color: #47B83A;
	font-family: 'Open Sans B';
}

.header-sticky {
	background-color: #06170D;
}

.header-sticky-mobile {
	position: fixed;
	display: flex;
	background-color: #06170D;
}

.header-dropdown-bg {
	position: relative;
	background-image: url('assets/images/iconos/nav-arrow.svg');
	background-repeat: no-repeat;
	background-position: left center;
}

.dropdown-content {
	display: none;
	position: absolute;
	left: -20px;
	background-color: transparent;
	z-index: 3;
	border-radius: 0px;
	padding-top: 30px;
	top: 0;
}

.dropdown-content ul {
	background-color: #fff !important;
}

.header-dropdown-bg:hover .dropdown-content {
	display: block;
}

.header-dropdown-bg:hover {
	background-image: url('assets/images/iconos/nav-arrow-hover.svg');
	background-repeat: no-repeat;
	background-position: left center;
	color: #47B83A;
	font-family: 'Open Sans B';
}

.header-dropdown-bg:hover a.header-dropdown {
	color: #47B83A;
	font-family: 'Open Sans B';
}

.dropdown-content ul {
	display: flex;
	flex-direction: column;
}

.dropdown-content ul li {
	display: inline-block;
	color: #103D22;
	font-family: 'Open Sans SB';
	border-bottom: 1px solid gray;
	padding-top: 20px;
	padding-bottom: 20px;
	padding-right: 30px;
}

.dropdown-content ul li:last-of-type {
	border-bottom: none;
}

.dropdown-content ul li a {
	color: #103D22;
}

.dropdown-content ul li a:hover {
	color: #103D22;
	font-family: 'Open Sans SB';
}

.header-dropdown-mobile-bg,
.header-dropdown-mobile-bg:not(:active) {
	background-image: url('assets/images/iconos/mobile-arrow.svg');
	background-repeat: no-repeat;
	background-position: 120px center;
}

.header-dropdown-mobile-bg:active .header-dropdown,
.header-dropdown-mobile-bg:hover .header-dropdown {
	display: block;
}

.header-dropdown-mobile-bg:active,
.header-dropdown-mobile-bg:hover {
	background-image: url('assets/images/iconos/mobile-arrow-hover.svg');
	background-repeat: no-repeat;
	background-position: 120px center;
	background-size: 15px;
}

.dropdown-content.dropdown-content-mobile {
	height: auto;
	left: 0;
	box-shadow: -7px 7px 15px 0px rgba(209, 209, 209, 1);
	-webkit-box-shadow: -7px 7px 15px 0px rgba(209, 209, 209, 1);
	-moz-box-shadow: -7px 7px 15px 0px rgba(209, 209, 209, 1);
}

.dropdown-content.dropdown-content-mobile ul li {
	padding: 15px;
	margin-bottom: 0;
}

@media screen and (max-width: 991.99px) {
	header {
		display: none;
	}
}


/* FOOTER */

.footer {
	background-image: url('assets/images/bg/footer.png');
	background-size: cover;
	padding-top: 5%;
	padding-bottom: 3%;
	position: relative;
	z-index: 1;
}

.footer-circulo1 {
	position: absolute;
	left: 0;
	top: 10px;
	z-index: -1;
}

.footer-circulo2 {
	position: absolute;
	right: 5%;
	top: -70px;
}

.footer-container {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding-bottom: 5%;
}

.footer-logo-container {
	display: flex;
	flex-direction: column;
	row-gap: 20px;
	width: 25%;
}

.footer-link-container {
	width: 17%;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.footer-link-title {
	word-break: keep-all;
	font-family: 'Moderat B';
	font-size: 24px;
	color: #ffffff;
	line-height: 120%;
	margin-bottom: 16px;
}

.footer-link {
	display: block;
	color: #ffffff;
	font-size: 16px;
	margin-bottom: 10px;
	line-height: 120%;
}

a.footer-link-title:hover {
	color: #47B83A;
	font-family: 'Open Sans B';
}

.footer-link:hover {
	font-family: 'Open Sans B';
	color: #47B83A;
}

.logo-footer-remitex {
	width: 160px;
}

.copy-container {
	display: flex;
	justify-content: space-between;
	padding-top: 20px;
	border-top: 1px solid #47B83A;
}

.copy-container p {
	color: #ffffff;
	font-size: 12px;
	margin-bottom: 0px;
}

.copy-logo-container {
	display: flex;
	align-items: center;
	column-gap: 15px;
}

.footer-social-container {
	display: flex;
	column-gap: 10px;
}

.footer-social-container img {
	width: 30px;
}

.footer-social-container a:not(:hover) img.footer-hover {
	display: none;
}

.footer-social-container a:hover img.footer-not-hover {
	display: none;
}

.footer-social-container a:hover img.footer-hover {
	display: block;
}

@media screen and (max-width: 992px) {
	.footer-circulo2 {
		width: 100px;
		top: -50px;
	}
}

@media screen and (max-width: 768px) {
	.footer-container {
		padding: 30px 0 0 0;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	.footer-container div {
		width: 100%;
		margin: auto;
		margin-bottom: 15px;
		text-align: center;
	}

	.footer-logo-container {
		margin: auto;
		text-align: center;
	}

	.footer-social-container {
		display: flex;
		align-items: center;
		justify-content: center;
		column-gap: 15px;
	}

	.logo-footer-remitex {
		width: 120px;
		margin: auto;
	}

	.footer-link-title {
		margin-bottom: 10px;
	}

	.copy-container {
		flex-wrap: wrap;
		justify-content: center;
		row-gap: 10px;
		padding-bottom: 30px;
	}

	.copy-logo-container {
		width: 100%;
		justify-content: center;
		text-align: center;
	}

	.footer-link-title {
		font-size: 20px;
	}

	.footer-link {
		font-size: 14px;
	}
}

@media screen and (max-width: 576px) {
	.footer-circulo2 {
		width: 80px;
		top: -30px;
	}

	.footer-circulo1 {
		width: 120px;
	}

	.copy-container p {
		font-size: 10px;
	}
}


/* HOME: S1 */

.home-s1 {
	background: url('assets/images/bg/home-s1.jpg'), #ffffff;
	background-position: bottom;
	background-size: cover;
	background-repeat: no-repeat;
	width: 100%;
	margin-bottom: 40px;
	overflow: hidden;
}

.homes1-container {
	max-width: 1280px;
	margin: auto;
	padding: 10% 0;
	position: relative;
}

.header-icono-right {
	position: absolute;
	top: 30%;
	right: 0;
}

.homes1-title-container {
	margin: auto;
	text-align: center;
	width: 50%;
}

.homes1-subtitle {
	font-family: 'Moderat B';
	color: white;
	font-size: 30px;
	margin-bottom: 50px;
	line-height: 130%;
}

.homes1-subtitle-verde {
	color: #47B83A;
}

.homes1-text {
	color: white;
	font-family: 'Open Sans B';
	text-align: center;
	font-size: 25px;
}

@media screen and (max-width: 992px) {
	.home-s1 {
		position: relative;
		z-index: 0;
	}

	.homes1-title-container {
		width: 70%;
	}

	.homes1-container {
		padding: 10% 0;
		padding-top: 15%;
		position: static;
	}

	.header-icono-right {
		right: 0;
		z-index: -1;
	}
}

@media screen and (max-width: 768px) {
	.homes1-subtitle {
		font-size: 21px;
	}

	.homes1-title-container {
		margin-top: 40px;
		width: 90%;
	}

	.homes1-container {
		padding-bottom: 10%;
	}

	.header-icono-right {
		top: unset;
		bottom: 80px;
		right: -30px;
		;
	}
}

.homes1-card-container {
	display: flex;
	column-gap: 30px;
	justify-content: center;
	padding-bottom: 90px;
}

.card-circulo {
	position: absolute;
	top: -30px;
	right: 30px;
}

.homes1-card {
	position: relative;
	display: flex;
	flex-direction: column;
	row-gap: 15px;
	width: 246px;
	height: 297px;
	padding: 40px 20px;
	padding-bottom: 60px;
	text-align: center;
	border-radius: 20px;
	opacity: .95;
}

.homes1-card-btn {
	width: auto;
	margin: auto;
	font-family: 'Open Sans SB';
	border-radius: 20px;
	padding: 10px 30px;
	color: white;
}

.homes1-card-btn:hover {
	color: white;
}

.homes1-card1 {
	background: rgb(0, 131, 255);
	background: transparent linear-gradient(180deg, #0083FF 0%, #004280D9 100%) 0% 0% no-repeat padding-box;
}

.homes1-card1 .homes1-card-btn {
	background: rgb(0, 131, 255);
	background: linear-gradient(180deg, rgba(0, 131, 255, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.homes1-card1:hover {
	background: rgb(0, 131, 255);
	background: transparent linear-gradient(180deg, #0083FF 0%, #00284D 100%) 0% 0% no-repeat padding-box;
	opacity: 1;
}

.homes1-card1:hover .homes1-card-btn {
	background: rgb(0, 131, 255);
	background: linear-gradient(0deg, rgba(0, 131, 255, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.homes1-card2 {
	background: rgb(67, 191, 209);
	background: transparent linear-gradient(180deg, #43BFD1 0%, #004280D9 100%) 0% 0% no-repeat padding-box;
}

.homes1-card2:hover {
	background: rgb(67, 191, 209);
	background: transparent linear-gradient(180deg, #43BFD1 0%, #002A53 100%) 0% 0% no-repeat padding-box;
	opacity: 1;
}

.homes1-card2 .homes1-card-btn {
	background: rgb(67, 191, 209);
	background: linear-gradient(180deg, rgba(67, 191, 209, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.homes1-card2:hover .homes1-card-btn {
	background: rgb(67, 191, 209);
	background: linear-gradient(0deg, rgba(67, 191, 209, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.homes1-card3 {
	background: rgb(126, 155, 255);
	background: linear-gradient(180deg, rgba(126, 155, 255, 1) 0%, rgba(0, 66, 128, 0.21) 100%);
}

.homes1-card3:hover {
	background: rgb(126, 155, 255);
	background: linear-gradient(180deg, rgba(126, 155, 255, 1) 0%, rgba(0, 47, 91, 1) 100%);
	opacity: 1;
}

.homes1-card3 .homes1-card-btn {
	background: rgb(126, 155, 255);
	background: linear-gradient(180deg, rgba(126, 155, 255, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.homes1-card3:hover .homes1-card-btn {
	background: rgb(126, 155, 255);
	background: linear-gradient(0deg, rgba(126, 155, 255, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.homes1-card-title {
	font-family: 'Moderat B';
	font-size: 27px;
	color: white;
	margin-bottom: 5px;
}

.homes1-card-text {
	min-height: 90px;
	color: white;
	font-size: 16px;
	line-height: 130%;
	width: 90%;
	margin: auto;
}

.carousel-homes1 {
	display: none !important;
}

@media screen and (max-width: 768px) {
	.owl-carousel .owl-item img.card-circulo {
		width: 70px;
	}
}


/* HOME: S2 */

.home-s2 {
	margin-bottom: 50px;
}

.homes2-title {
	font-family: 'Moderat B';
	font-size: 40px;
	color: #103D22;
	text-align: center;
	line-height: 130%;
	margin: auto;
	margin-bottom: 25px;
}

.homes2-title-verde {
	color: #47B83A;
}

.homes2-text {
	font-size: 16px;
	color: #103D22;
	text-align: center;
	line-height: 130%;
	width: 50%;
	margin: auto;
	margin-bottom: 35px;
}

.homes2-icon-container {
	width: 80%;
	margin: auto;
	border: 1px solid #0909092E;
	border-radius: 33px;
	padding: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin-bottom: 10%;
}

.homes2-icon {
	width: 25%;
	row-gap: 30px;
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.homes2-icon p {
	color: #47B83A;
	font-family: 'Open Sans SB';
	font-size: 16px;
	line-height: 120%;
	margin-bottom: 0;
}

.homes2-btn-container {
	position: absolute;
	top: 95%;
}

.homes2-btn {
	background: rgb(71, 184, 58);
	background: linear-gradient(90deg, rgba(71, 184, 58, 1) 0%, rgba(32, 119, 60, 1) 100%);
	padding: 10px 30px;
	border-radius: 20px;
	font-family: 'Open Sans SB';
	color: white;
}

.homes2-btn:hover {
	border: 2px solid #47B83A;
	background: white;
	padding: 8px 28px;
	color: #20773C;
}

@media screen and (max-width: 992px) {
	.homes2-text {
		width: 70%;
	}
}

@media screen and (max-width: 768px) {
	.homes1-card-text {
		min-height: 84px;
	}

	.home-s4 {
		background-size: auto;
		margin-bottom: 50px;
	}

	.homes4-title {
		font-size: 28px;
		width: 60%;
		margin: auto;
		margin-bottom: 50px;
		text-align: center;
	}

	.homes1-card-container {
		column-gap: 15px;
	}

	.homes1-card-title {
		font-size: 28px;
	}

	.homes1-card {
		height: 320px;
		margin: auto;
	}

	.homes1-card-container {
		display: none;
	}

	.carousel-homes1 {
		display: block !important;
		padding-bottom: 70px;
	}

	.carousel-homes1.owl-carousel .owl-nav {
		margin: auto;
		text-align: center;
	}

	.carousel-homes1.owl-carousel .owl-nav button {
		color: rgba(255, 255, 255, .7);
		font-size: 40px;
		margin-right: 10px;
	}

	.owl-carousel.carousel-homes1 .owl-stage-outer {
		overflow: visible;
	}

	.homes2-title {
		font-size: 28px;
	}

	.homes2-text {
		width: 90%;
	}

	.homes2-icon-container {
		flex-wrap: wrap;
		row-gap: 30px;
	}

	.homes2-icon {
		width: 50%;
		row-gap: 10px;
	}

	.home-s2 {
		margin-bottom: 100px;
	}
}

@media screen and (max-width: 576px) {
	.homes2-icon-container {
		width: 100%;
	}

	.homes1-card-text {
		min-height: 104px;
	}

	.homes1-title-container {
		margin-top: 60px;
	}

	.homes2-icon-container {
		padding-right: 20px;
		padding-left: 20px;
	}
}

@media screen and (max-width: 440px) {
	.homes2-icon-container {
		padding-right: 10px;
		padding-left: 10px;
	}
}


/* REMITTANCES */

.remittances-s1 {
	background-image: url('assets/images/bg/remittances-s1.jpg');
	position: relative;
}

.remittances-s1 .homes1-container {
	position: static;
	padding-bottom: 15%;
}

.remittances-icono-right {
	width: 25vw;
	position: absolute;
	right: 0;
	top: 20%;
}

.api-icono {
	width: 20vw;
}

.homes1-subtitle-celeste {
	color: rgba(67, 191, 209, 1);
}

.remittances1-title-container {
	width: 45%;
	text-align: center;
	margin: auto;
}

.remittances1-title-container .homes1-subtitle {
	font-size: 34px;
	line-height: 130%;
}

.remittances-title {
	font-family: 'Open Sans B';
	color: white;
	font-size: 28px;
}

.remittances-text {
	width: 80%;
	margin: auto;
	color: white;
	font-size: 16px;
	line-height: 130%;
	margin-bottom: 70px;
}

.remmittances-s1-btn {
	margin-bottom: 60px;
}

.remittances-btn {
	width: auto;
	margin: auto;
	font-family: 'Open Sans SB';
	border-radius: 20px;
	padding: 10px 30px;
	color: white;
	background: rgb(67, 191, 209);
	background: linear-gradient(180deg, rgba(67, 191, 209, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.remittances-btn:hover {
	background: rgb(67, 191, 209);
	background: linear-gradient(0deg, rgba(67, 191, 209, 1) 0%, rgba(0, 42, 83, 1) 100%);
	color: white;
}

@media screen and (max-width: 1100px) {
	.remittances1-title-container {
		width: 55%;
	}
}

@media screen and (max-width: 992px) {
	.remittances1-title-container {
		width: 65%;
	}

	.remittances-s1 .homes1-container {
		padding-bottom: 20%;
	}

	.remittances-s2 .container-1280 {
		margin-top: -15%;
	}
}

@media screen and (max-width: 768px) {
	.remittances1-title-container .homes1-subtitle {
		font-size: 24px;
		margin-bottom: 30px;
	}

	.remittances-title {
		font-size: 21px;
	}

	.remittances-s1 .homes1-container {
		padding-bottom: 25%;
	}

	.remittances-icono-right {
		display: none;
	}

	.remittances-s2 .container-1280 {
		margin-top: -20%;
	}
}

@media screen and (max-width: 576px) {
	.remittances1-title-container {
		width: 90%;
	}

	.remittances-s1 .homes1-container {
		padding-top: 20%;
		padding-bottom: 30%;
	}

	.remittances-s2 .container-1280 {
		margin-top: -25%;
	}

	.remittances-btn {
		font-size: 14px;
	}
}

@media screen and (max-width: 440px) {
	.remittances-s1 .homes1-container {
		padding-top: 25%;
		padding-bottom: 35%;
	}

	.remittances-s2 .container-1280 {
		margin-top: -30%;
	}
}

@media screen and (max-width: 380px) {
	.remittances-s1 .homes1-container {
		padding-top: 25%;
		padding-bottom: 40%;
	}

	.remittances-s2 .container-1280 {
		margin-top: -40%;
	}
}

@media screen and (max-width: 340px) {
	.remittances-s1 .homes1-container {
		padding-top: 25%;
		padding-bottom: 45%;
	}

	.remittances-s2 .container-1280 {
		margin-top: -45%;
	}
}

.homes2-title-celeste {
	color: rgba(67, 191, 209, 1);
}

.remittances-s2 {
	margin-top: -10%;
	position: relative;
	z-index: 1;
}

.remmitances-diagrama {
	display: block;
	margin: auto;
	margin-top: 40px;
	margin-bottom: 40px;
}

.remmitances-icon {
	row-gap: 10px;
}

.remmitances-icon p {
	font-family: 'Open Sans B';
	color: #103D22;
}

.remmitances-icon .homes2-icon-img-container {
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
}

@media screen and (max-width: 1200px) {
	.remittances-icon-container .homes2-icon-container {
		width: 95%;
	}

	.remmitances-icon {
		width: 30%;
	}
}

@media screen and (max-width: 768px) {
	.remittances-s2 {
		margin-top: -10%;
	}

	.remmitances-icon {
		width: 50%;
	}
}

@media screen and (max-width: 576px) {
	.remittances-s2 {
		margin-top: -15%;
	}
}

@media screen and (max-width: 440px) {
	.remittances-s2 {
		margin-top: -20%;
	}
}


/* CROSSBORDER */

.crossborder-s1 {
	background-image: url('assets/images/bg/crossborder-s1.jpg');
}

.homes1-subtitle-azul {
	color: rgb(0, 131, 255);
}

.crossborder-btn {
	width: auto;
	margin: auto;
	font-family: 'Open Sans SB';
	border-radius: 20px;
	padding: 10px 30px;
	color: white;
	background: rgb(0, 131, 255);
	background: linear-gradient(180deg, rgba(0, 131, 255, 1) 0%, rgba(0, 42, 83, 1) 100%);
}

.crossborder-btn:hover {
	color: white;
	background: rgb(0, 131, 255);
	background: linear-gradient(0deg, rgba(0, 131, 255, 1) 0%, rgba(0, 42, 83, 1) 100%);
	border: none;
}

.crossborder-s1 .remmittances-s1-btn {
	margin-bottom: 160px;
}

@media screen and (max-width:768px) {
	.crossborder-s1 .homes1-container {
		padding-bottom: 10%;
	}
}

@media screen and (max-width:576px) {
	.crossborder-s1 .homes1-container {
		padding-top: 20%;
		padding-bottom: 15%;
	}

	.crossborder-btn {
		font-size: 14px;
	}
}

@media screen and (max-width: 440px) {
	.crossborder-s1 .homes1-container {
		padding-top: 25%;
		padding-bottom: 25%;
	}
}

.homes2-title-azul {
	color: rgba(0, 131, 255, 1);
}

.crossborders2-card-container {
	display: flex;
	column-gap: 50px;
	justify-content: center;
	align-items: flex-start;
	margin-bottom: 60px;
	width: 70%;
	margin: auto;
	margin-bottom: 5%;
}

.crossborders2-card {
	width: 45%;
	padding: 50px 30px;
	position: relative;
}

.crossborders2-card::before {
	content: "";
	position: absolute;
	inset: 0;
	border-radius: 20px;
	padding: 3px;
	background: linear-gradient(to bottom, rgba(0, 131, 255, 1), rgba(92, 78, 54, 0));
	-webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
	-webkit-mask-composite: xor;
	mask-composite: exclude;
}

.crossborders2-card-img {
	height: 60px;
	padding-bottom: 15px;
	margin: auto;
	display: block;
}

.crossborders2-card-text-fondo {
	padding: 15px;
	color: rgba(0, 131, 255, 1);
	text-align: center;
	margin-bottom: 0;
	background-color: rgba(0, 131, 255, .2);
	font-family: 'Open Sans B';
	margin-bottom: 15px;
	font-size: 21px;
}

.crossborders2-card-text {
	color: rgba(0, 131, 255, 1);
	text-align: center;
	margin-bottom: 0;
	font-size: 15px;
	font-family: 'Open Sans SB';
	line-height: 140%;
}

.crossborder-s2 .homes2-icon-container {
	padding: 60px 30px;
	column-gap: 20px;
	align-items: flex-start;
}

.crossborder-s2 .homes2-icon-img-container {
	height: 55px;
}

.crossborder-s2 .homes2-icon {
	row-gap: 20px;
}

.crossborder-s2 .homes2-icon p {
	color: #103D22;
	font-size: 14px;
	font-family: 'Open Sans B';
}

.crossborder-s2 {
	margin-top: -180px;
	position: relative;
	z-index: 1;
}

@media screen and (max-width: 1600px) {
	.crossborder-s2 {
		margin-top: -150px;
	}
}

@media screen and (max-width: 992px) {
	.crossborder-s2 .homes2-icon-container {
		flex-wrap: wrap;
		column-gap: 20px;
	}

	.crossborder-s2 .homes2-icon {
		width: 45%;
	}

	.crossborder-s2 .homes2-btn-container {
		top: 97%;
	}

	.crossborders2-card-container {
		width: 90%;
	}
}

.owl-carousel.owl-theme.carousel-crossborder2 {
	display: none;
}

@media screen and (max-width: 768px) {
	.crossborder-s2 {
		margin-top: -120px;
	}

	.crossborders2-card-container {
		display: none;
	}

	.owl-carousel.owl-theme.carousel-crossborder2 {
		display: block;
	}

	.carousel-crossborder2 .crossborders2-card {
		width: 90%;
		max-width: 337px;
		margin: auto;
	}

	.owl-carousel.carousel-crossborder2 .owl-dots button {
		border: 1px solid #0083ff;
		margin-bottom: 40px;
	}

	.owl-carousel.carousel-crossborder2 .owl-dots button.owl-dot.active {
		background-color: #0083ff;
	}
}

.crossborder-btn-secondary-container {
	width: 40%;
	margin: auto;
	display: flex;
	column-gap: 30px;
}

.crossborder-btn-secondary {
	color: white;
	background-color: #0083ff;
	border-radius: 40px;
	padding: 10px 30px;
	font-family: 'Open Sans SB';
	text-align: center;
	font-size: 14px;
	border: 2px solid transparent;
	line-height: 120%;
	display: inline-block;
}

.crossborder-btn-secondary:hover {
	background-color: white;
	color: #0083ff;
	border: 2px solid #0083ff;
}

@media screen and (max-width: 992px) {
	.crossborder-btn-secondary-container {
		width: 60%;
	}
}

@media screen and (max-width: 768px) {
	.crossborder-btn-secondary-container {
		width: 80%;
	}
}

@media screen and (max-width: 576px) {
	.crossborder-btn-secondary-container {
		flex-wrap: wrap;
		row-gap: 20px;
		margin-top: 60px;
	}

	.crossborder-btn-secondary {
		width: 60%;
		margin: auto;
	}
}

@media screen and (max-width: 440px) {
	.crossborder-btn-secondary {
		width: 85%;
		margin: auto;
	}
}


/* API INTEGRATION */

.api-s1 {
	background-image: url('assets/images/bg/api-s1.jpg');
}

.api-s1 .homes1-container {
	padding-bottom: 20%;
}

.apis1-subtitle-azul {
	color: #75baff;
}

.api-s1 .remittances-text {
	width: 70%;
}

.api-s2 .homes1-card .homes1-card1 {
	opacity: 1;
	background: transparent linear-gradient(180deg, #0083FF 0%, #004280D9 100%) 0% 0% no-repeat padding-box;
}

.api-s2 .homes1-card .homes1-card2 {
	opacity: 1;
	background: transparent linear-gradient(180deg, #43BFD1 0%, #004280D9 100%) 0% 0% no-repeat padding-box;
	;
}

.api-s2 .homes2-icon-container {
	padding: 20px 40px;
	width: fit-content;
}

.api-btn {
	width: auto;
	margin: auto;
	font-family: 'Open Sans SB';
	border-radius: 20px;
	padding: 10px 30px;
	color: white;
	background: transparent linear-gradient(177deg, #7E9BFF 0%, #002A53 100%) 0% 0% no-repeat padding-box;
}

.api-btn:hover {
	background: transparent linear-gradient(180deg, #002A53 0%, #7E9BFF 100%) 0% 0% no-repeat padding-box;
	color: white;
}

.api-s2 .homes2-btn-container {
	top: 98%;
}

.api-s2 .owl-carousel .owl-dots button.owl-dot.active {
	background-color: #06170D;
}

.api-s2 .owl-carousel .owl-dots button.owl-dot {
	border: 1px solid #06170D;
	margin: 10px 5px;
}

@media screen and (max-width: 992px) {
	.api-s1 .homes1-container {
		padding-bottom: 25%;
	}
}

@media screen and (max-width: 768px) {
	.api-s1 .homes1-container {
		padding-top: 15%;
		padding-bottom: 25%;
	}

	.api-s1 .remittances-text {
		width: 90%;
	}
}

@media screen and (max-width: 576px) {
	.api-s1 .homes1-container {
		padding-top: 25%;
		padding-bottom: 35%;
	}
}

@media screen and (max-width: 380px) {
	.api-s1 .homes1-container {
		padding-bottom: 40%;
	}
}


/* SOBRE NOSOTROS */

.about-s1 {
	background-image: url('assets/images/bg/us-s1.jpg');
}

.abouts1-subtitle-verde {
	color: #47b83a;
}

.about-s1 .remittances-text {
	font-size: 21px;
	width: auto;
}

.about-s1 .remittances1-title-container .homes1-subtitle {
	font-size: 36px;
}

@media screen and (max-width: 768px) {
	.about-s1 .remittances-text {
		font-size: 16px;
	}

	.about-s1 .remittances1-title-container .homes1-subtitle {
		font-size: 28px;
	}
}

.mapa {
	width: auto;
	margin: auto;
	display: block;
}

.mapa-mobile {
	display: none;
}

.about-s3 {
	background-image: url('assets/images/bg/us-s3.jpg');
	background-size: cover;
	background-position: center center;
}

@media screen and (min-width: 1700px) {
	.about-s3 {
		background-size: 100% 100%;
	}
}

.about-s3-container {
	padding: 150px 0;
}

.about-s3-container-title {
	color: #ffffff;
	font-size: 16px;
	width: 40%;
	margin: auto;
	text-align: center;
	margin-bottom: 30px;
}

.about-s3-icon-container.homes2-icon-container {
	column-gap: 50px;
	border: none;
	width: 100%;
	margin-bottom: 30px;
}

.about-s3-icon-container .homes2-icon {
	background: rgba(255, 255, 255, 0.15);
	padding: 23px 48px;
	border-radius: 30px;
	width: 25%;
	max-width: 175px;
	min-height: 133px;
	row-gap: 5px;
}

.about-s3-icon-container .homes2-icon .homes2-icon-text {
	font-family: 'Moderat B';
	font-size: 14px;
	line-height: 120%;
	margin-bottom: 0;
	color: #fff;
}

.about-s3-icon-title p {
	font-size: 40px;
	color: #fff;
	font-family: 'Open Sans B';
}

@media screen and (max-width: 768px) {
	.about-s1 .homes1-container {
		padding-top: 20%;
		padding-bottom: 10%;
	}
}

@media screen and (max-width: 992px) {
	.about-s3-icon-container.homes2-icon-container {
		column-gap: 25px;
		flex-wrap: wrap;
	}

	.about-s3-icon-container .homes2-icon {
		max-width: 150px;
	}
}

@media screen and (max-width: 830px) {
	.mapa-desktop {
		display: none;
	}

	.mapa-mobile {
		display: block;
	}

	.about-s3-container {
		padding-bottom: 60px;
	}

	.about-s3 {
		margin-bottom: 0;
	}

	.about-s3-container-title {
		width: 60%;
		margin-bottom: 0;
	}

	.about-s3-icon-container.homes2-icon-container {
		row-gap: 30px;
	}

	.about-s3-icon-container .homes2-icon {
		width: 50%;
		max-width: 45%;
	}
}

@media screen and (max-width: 768px) {
	.about-s3-icon-title p {
		font-size: 36px;
	}
}

@media screen and (max-width: 576px) {
	.about-s3-container-title {
		width: 90%;
	}
}


/* CONTACT FORM*/
.contact-cta {
	max-width: 450px;
    text-align: center;
    margin: 20px auto;
}

.contact-cta a {
    margin: 30px 0;
    display: inline-block;
}

section.contact {
	background-image: url(assets/images/bg/bg-form.png);
	/*min-height: 1124px;*/
	background-repeat: no-repeat;
	background-size: cover;
	background-position: top;
	margin-top: 100px;
}


section.contact iframe {
	width: 100%;
	height: 1175px;
}

section.contact img.left {
	position: absolute;
	left: 0;
	top: -120px;
}

section.contact img.right {
	position: absolute;
	right: 0;
	top: -120px;
}

@media screen and (max-width: 768px) {
	section.contact img.left {
		display: none;
	}

	section.contact img.right {
		display: none;
	}
}
